import React from "react";
import {
  ContentWrapper,
  ConveyorBeltImg,
  DesktopContent,
  DownloadArea,
  DownloadText,
  HeaderText,
  ImagesContainer,
  LeftContentWrapper,
  LeftSide,
  MainContainer,
  MobileButtonContainer,
  MobileContent,
  MobileGifContainer,
  MobileImg,
  MobileMottoContainer,
  MottoContainer,
  RightSide,
  StyledImage,
} from "./styles";
import Google from "../../assets/images/Google.png";
import Apple from "../../assets/images/Apple.png";
import EmailSignUp from "./EmailSignUp";
import LandingPageGraphic from "./assets/LandingPageGraphic.png";
import { AppButton, AppButtonContainer, Subheader } from "../MobileApp/styles";

export default function LandingPage() {
  return (
    <MainContainer>
      {/* Desktop View */}
      <DesktopContent>
        <ContentWrapper>
          <LeftSide>
            <LeftContentWrapper>
              <MottoContainer>
                <HeaderText>Connecting the world</HeaderText>
                <HeaderText>to building information</HeaderText>
              </MottoContainer>
              <EmailSignUp />
              <DownloadArea>
              <DownloadText>Download Today</DownloadText>
              <AppButtonContainer>
                <a
                  href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
                  id="AppleAppStore"
                >
                  <AppButton src={Apple} alt="Download on Apple App store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
                  id="GooglePlayStore"
                >
                  <AppButton src={Google} alt="Download on Google Play store" />
                </a>
              </AppButtonContainer>
              
              <ImagesContainer>
                <StyledImage
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2Frobo%20png%20mobile.png?alt=media&token=e85acdae-7325-49f0-b604-c6e65068e90b"
                  alt="Mobile app screenshot"
                />
                <StyledImage
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FWebApp%2FMAC%20MOCKUP%20v2.png?alt=media&token=a0fd7d6a-9be3-45b0-9c46-0930bbac44fa"
                  alt="Web app screenshot"
                />
              </ImagesContainer>
              </DownloadArea>
            </LeftContentWrapper>
          </LeftSide>
          <RightSide>
            <ConveyorBeltImg
              src={LandingPageGraphic}
              alt="Landing Page Graphic"
            />
          </RightSide>
        </ContentWrapper>
      </DesktopContent>

      {/* Mobile View */}
      <MobileContent>
        <MobileMottoContainer>
          <HeaderText>Connecting the World</HeaderText>
          <HeaderText>to Building Information</HeaderText>
        </MobileMottoContainer>

        <MobileGifContainer>
          <MobileImg src={LandingPageGraphic} alt="Landing Page Graphic" />
        </MobileGifContainer>
        <EmailSignUp />

        <MobileButtonContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
          >
            <img src={Apple} alt="download on app store" />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
          >
            <img src={Google} alt="download on play store" />
          </a>
        </MobileButtonContainer>
      </MobileContent>
    </MainContainer>
  );
}
